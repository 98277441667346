<template>
  <section id="section-joinus" class="bg-monochrome-grey-super-light-grey">
    <div class="nstra-container">
      <div class="section-body">
        <b-row>
          <b-col v-scrollanimation="'left'" cols="12" lg="6">
            <h2 class="joinus-title bold">
              <span class="text-primary">Join a team</span>
              with a passion for creating products that people love!
            </h2>
            <p class="joinus-text">
              At Nostratech, we ensure to create an environment of highly motivated individuals who inspire and drive creative, technical and analytical thinking. You will be surrounded by highly
              dedicated, technically sound individuals who are driven to continuously improve and are excited to experience a journey to be the very best.
            </p>
            <div class="mb-30px mb-lg-0px">
              <router-link to="/career">
                <b-button variant="primary">
                  View Career
                </b-button>
              </router-link>
            </div>
          </b-col>
          <b-col v-scrollanimation="'right'" cols="12" lg="6">
            <img class="story-img reguler-shadow reguler-border-radius" src="@/assets/images/about/img-joinus.jpeg" alt="join us image" />
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
};
</script>
